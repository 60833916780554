import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import LeftSideMenuAdmin from "./LeftSideMenuAdmin";
import "./AdminDashboard.css";
import bjplogo from "../assets/bjp.png"; // Adjust the path according to your folder structure
import whatsappLogo from "../assets/whatsapp.png"; // Import WhatsApp logo image
import congresslogo from "../assets/congress.png";
import sslogo from "../assets/shivsena-shinde.png";
import ssubtlogo from "../assets/shivsena-ubt.png";
import n1logo from "../assets/ncp1.jpeg";
import n2logo from "../assets/ncp2.png";
import mnslogo from "../assets/mns.png";

function AdminDashboard() {
  const [pendingUsers, setPendingUsers] = useState([]); // State for pending users
  const [acceptedUsers, setAcceptedUsers] = useState([]); // State for accepted users
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [adminUsername, setAdminUsername] = useState(""); // State to hold the admin username
  const navigate = useNavigate();
  const partyimg = localStorage.getItem("party");

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        // Call API to get users based on logged-in admin's constituency
        const response = await axios.get(
          "https://dhyas.site/api/admin/dashboard",
          { withCredentials: true }
        );
        setPendingUsers(response.data.pendingUsers); // Set pending users
        setAcceptedUsers(response.data.acceptedUsers); // Set accepted users
        setAdminUsername(response.data.username); // Set admin username from API
      } catch (error) {
        setError("Failed to fetch users."); // Set error message
        console.error("Fetch users error:", error);
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchUsers();
  }, []);

  const handleAccept = async (username) => {
    try {
      await axios.post(
        "https://dhyas.site/api/admin/accept",
        { username },
        { withCredentials: true }
      );
      // Update state after accepting a user
      setPendingUsers(
        pendingUsers.filter((user) => user.username !== username)
      );
      const user = pendingUsers.find((user) => user.username === username);
      if (user) {
        setAcceptedUsers((prevUsers) => [...prevUsers, user]);
      }
    } catch (error) {
      setError("Failed to accept user."); // Set error message
      console.error("Accept user error:", error);
    }
  };

  const handleRefuse = async (username) => {
    try {
      await axios.post(
        "https://dhyas.site/api/admin/refuse",
        { username },
        { withCredentials: true }
      );
      setPendingUsers(
        pendingUsers.filter((user) => user.username !== username)
      ); // Remove refused user from pending list
    } catch (error) {
      setError("Failed to refuse user."); // Set error message
      console.error("Refuse user error:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        "https://dhyas.site/api/logout",
        {},
        { withCredentials: true }
      );
      navigate("/login"); // Redirect to login after logout
    } catch (error) {
      setError("Failed to logout."); // Set error message
      console.error("Logout error:", error);
    }
  };

  


  return (
    <div className="admin-dashboard-container">
      <LeftSideMenuAdmin isAdmin={true} />
      <div className="container">
        <header className="admin-header">
          {/* Add the BJP logo (bjp2.png) at the top of the page */}
          <img
            src={
              partyimg == "BJP"
            ? bjplogo
            : partyimg == "Congress"
            ? congresslogo
            : partyimg == "Shivsena-Shinde"
            ? sslogo
            : partyimg == "Shivsena-UBT"
            ? ssubtlogo
            : partyimg == "NCP-A"
            ? n1logo
            : partyimg == "NCP-S"
            ? n2logo
            : partyimg == "MNS"
            ? mnslogo
            : null
            }
            alt={  partyimg == "BJP"
              ? "bjplogo"
              : partyimg == "Congress"
              ? "congress"
              : partyimg == "Shivsena-Shinde"
              ? "shivsena-shinde"
              : partyimg == "Shivsena-UBT"
              ? "shivsena-ubt"
              : partyimg == "NCP-A"
              ? "NCP-A"
              : partyimg == "NCP-S"
              ? "NCP-S"
              : partyimg == "MNS"
              ? "mns"
              : null}
            className="top-logo"
          />

          <button onClick={handleLogout} aria-label="Logout">
            Logout
          </button>
        </header>
        <h1>Admin Panel</h1>
        <h5>SignUp Requests</h5>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <>
            <div className="user-list">
              <h3>Pending Users</h3>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Profile Image</th>
                    <th>Username</th>
                    <th>Constituency</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingUsers.length > 0 ? (
                    pendingUsers.map((user) => (
                      <tr key={user.username}>
                        <td>
                          <img
                            src={user.profileImage} // URL or local path to user image
                            alt={`${user.username}'s profile`}
                            className="user-image"
                          />
                        </td>
                        <td>{user.username}</td>
                        <td>{user.constituency}</td>
                        <td>
                          <div className="user-actions">
                            <button
                              className="accept-button"
                              onClick={() => handleAccept(user.username)}
                            >
                              Accept
                            </button>
                            <button
                              className="refuse-button"
                              onClick={() => handleRefuse(user.username)}
                              style={{ marginLeft: "10px" }}
                            >
                              Refuse
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No pending users.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="user-list">
              <h3>Accepted Users</h3>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Profile Image</th>
                    <th>Username</th>
                    <th>Constituency</th>
                  </tr>
                </thead>
                <tbody>
                  {acceptedUsers.length > 0 ? (
                    acceptedUsers.map((user) => (
                      <tr key={user.username}>
                        <td>
                          <img
                            src={user.profileImage} // URL or local path to user image
                            alt={`${user.username}'s profile`}
                            className="user-image"
                          />
                        </td>
                        <td>{user.username}</td>
                        <td>{user.constituency}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        No accepted users.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        <Outlet />
      </div>
    </div>
  );
}

export default AdminDashboard;
