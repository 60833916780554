import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./VoterList.css";

const SurnamewiseList = () => {
  const [voters, setVoters] = useState([]);
  const [error, setError] = useState(null);
  const [change, setChange] = useState("");
  const [filters, setFilters] = useState({
    Epic_ID: "",
    Name: "",
    Middle_Name: "",
    Age: "",
    Gender: "",
    Name_Eng: "",
    Marathi_Name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [votersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalVoterCount, setTotalVoterCount] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "Name",
    direction: "asc",
  });
  const [expandedSurname, setExpandedSurname] = useState(null); // Track the currently expanded surname
  const navigate = useNavigate();

  // Fetch voters from the API
  useEffect(() => {
    const fetchVoterData = async () => {
      try {
        const response = await axios.get(
          `https://dhyas.site/api/user/surname/data?page=${currentPage}&` +
            `Epic_ID=${filters.Epic_ID || ""}` +
            `&Name=${filters.Name || ""}` +
            `&Middle_Name=${filters.Middle_Name || ""}` +
            `&Age=${filters.Age || ""}` +
            `&Gender=${filters.Gender || ""}` +
            `&Name_Eng=${filters.Name_Eng || ""}` +
            `&Marathi_Name=${filters.Marathi_Name || ""}`,
          { withCredentials: true }
        );
        
        console.log("Total Voter Count:", response.data.totalCount);
        console.log("Voter Data on Current Page:", response.data.userData);
        
        setTotalPages(Math.ceil(response.data.userCount / votersPerPage));
        setVoters(response.data.userData);
        setTotalVoterCount(response.data.totalCount);
      } catch (error) {
        setError("Failed to fetch voters. Please try again later.");
        console.error("Fetch voters error:", error);
      }
    };
    fetchVoterData();
  }, [currentPage, change, filters]);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle filter input change
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setChange(!change);
  };

  const handleClearFilters = () => {
    setFilters({
      Epic_ID: "",
      Name: "",
      Middle_Name: "",
      Age: "",
      Gender: "",
      Name_Eng: "",
      Marathi_Name: "",
    });
    setCurrentPage(1);
  };

  const handleLogout = () => {
    axios
      .post("https://dhyas.site/api/user/logout", null, {
        withCredentials: true,
      })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        setError("Logout failed. Please try again.");
        console.error("Logout error:", error);
      });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Group voters by surname
  const groupedVoters = {};
  voters.forEach((voter) => {
    const surname = voter.Name.split(" ").slice(-1)[0]; // Get the surname
    if (!groupedVoters[surname]) {
      groupedVoters[surname] = [];
    }
    groupedVoters[surname].push(voter); // Add the voter to the surname group
  });

  // Convert grouped object into an array for rendering
  const groupedVoterArray = Object.entries(groupedVoters);

  // Toggle surname visibility
  const toggleSurname = (surname) => {
    // If the clicked surname is already expanded, collapse it; otherwise, set it as expanded
    setExpandedSurname((prev) => (prev === surname ? null : surname));
  };

  // Navigate to voter's detailed view
  const handleView = (voterId) => {
    navigate(`/admin/voters/view/${voterId}`);
  };

  return (
    <div className="voter-list-container">
      <div className="header">
        <h1 className="top-box">Voter List</h1>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="filter-section">
        <form onSubmit={handleFilterSubmit}>
          <div className="filter-input">
            <label>Name:</label>
            <input
              type="text"
              name="Name"
              value={filters.Name}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-input">
            <label>Epic ID:</label>
            <input
              type="text"
              name="Epic_ID"
              value={filters.Epic_ID}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-button-group">
            <button className="clear-button" type="submit">
              Apply Filters
            </button>
            <button className="clear-button" type="button" onClick={handleClearFilters}>
              Clear Filters
            </button>
            <button
              className="print-button no-print"
              onClick={() => window.print()}
            >
              Print List
            </button>
          </div>
        </form>
      </div>

      <div className="table-container">
        <table className="voter-table">
          <thead>
            <tr>
              <th onClick={() => handleSort("Surname")}>Surname</th>
              <th className="one">Action</th>
            </tr>
          </thead>
          <tbody>
            {groupedVoterArray.map(([surname, voters]) => (
              <React.Fragment key={surname}>
                <tr onClick={() => toggleSurname(surname)} style={{ cursor: "pointer" }}>
                  <td>{surname}</td>
                  <td className="one">
                    <button
                      onClick={(e) => { 
                        e.stopPropagation(); // Prevent row click when clicking on the button
                        handleView(voters[0]._id); // Use the first voter ID for the button action
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
                {expandedSurname === surname && voters.map((voter) => (
                  <tr key={voter.Epic_ID}>
                    <td>{voter.Name}</td>
                    <td className="one">
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click when clicking on the button
                          handleView(voter._id);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SurnamewiseList;
