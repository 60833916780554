import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./VoterList.css";

const AlphabeticalList = () => {
  const [voters, setVoters] = useState([]);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    Epic_ID: "",
    Name: "",
    Middle_Name: "",
    Age: "",
    Gender: "",
    Name_Eng: "",
    Marathi_Name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [votersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalVoterCount, setTotalVoterCount] = useState(0);
  const navigate = useNavigate();

  // Fetch voters from the API
  useEffect(() => {
    const fetchVoterData = async () => {
      try {
        const response = await axios.get(
          `https://dhyas.site/api/user/alpb/data?page=${currentPage}&` +
            `Epic_ID=${filters.Epic_ID || ""}` +
            `&Name=${filters.Name || ""}` +
            `&Middle_Name=${filters.Middle_Name || ""}` +
            `&Age=${filters.Age || ""}` +
            `&Gender=${filters.Gender || ""}` +
            `&Name_Eng=${filters.Name_Eng || ""}` +
            `&Marathi_Name=${filters.Marathi_Name || ""}`,
          { withCredentials: true }
        );

        const { userCount, userData, totalCount } = response.data;
        setTotalPages(Math.ceil(userCount / votersPerPage));
        setVoters(userData);
        setTotalVoterCount(totalCount);
      } catch (error) {
        setError("Failed to fetch voters. Please try again later.");
        console.error("Fetch voters error:", error);
      }
    };

    fetchVoterData();
  }, [currentPage, filters]); // Update to use filters directly

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle filter input change
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    // Directly handle the state change in the effect
  };

  const handleClearFilters = () => {
    setFilters({
      Epic_ID: "",
      Name: "",
      Middle_Name: "",
      Age: "",
      Gender: "",
      Name_Eng: "",
      Marathi_Name: "",
    });
    setCurrentPage(1); // Reset to first page after clearing filters
  };

  const handleLogout = () => {
    axios
      .post("https://dhyas.site/api/user/logout", null, {
        withCredentials: true,
      })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        setError("Logout failed. Please try again.");
        console.error("Logout error:", error);
      });
  };

 
  // Navigate to voter's detailed view
  const handleView = (voterId) => {
    navigate(`/admin/voters/view/${voterId}`);
  };

  return (
    <div className="voter-list-container">
      <div className="header">
        <h1 className="top-box">Voter List</h1>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="filter-section">
        {Object.entries(filters).map(([key, value]) => (
          <div className="filter-input" key={key}>
            <label>{key.replace("_", " ")}:</label>
            {key === "Gender" ? (
              <select name={key} value={value} onChange={handleFilterChange}>
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            ) : (
              <input
                type={key === "Age" ? "number" : "text"}
                name={key}
                value={value}
                onChange={handleFilterChange}
              />
            )}
          </div>
        ))}
      </div>

      <div className="filter-button-group">
        <button className="clear-button" onClick={handleFilterSubmit}>
          Apply Filters
        </button>
        <button className="clear-button" onClick={handleClearFilters}>
          Clear Filters
        </button>
        <button
          className="print-button no-print"
          onClick={() => window.print()}
        >
          Print List
        </button>
      </div>

      <div className="table-container">
        <table className="voter-table">
          <thead>
            <tr>
              <th>Epic ID</th>
              <th>Name</th>
              <th>Surname</th>
              <th className="one">Action</th>
            </tr>
          </thead>
          <tbody>
            {voters.length === 0 ? (
              <tr>
                <td colSpan="3">No voters found.</td>
              </tr>
            ) : (
              voters.map((voter) => (
                <tr
                  key={voter.Epic_ID}
                  onClick={() => handleView(voter._id)} // Navigate to voter's detail on row click
                  style={{ cursor: "pointer" }} // Add cursor pointer style for better UX
                >
                  <td>{voter.Epic_ID}</td>
                  <td>{voter.Name ? voter.Name_Eng : ""}</td> {/* Display only the surname */}
                  <td>{voter.Name ? voter.Name.split(" ").pop() : ""}</td> {/* Display only the surname */}
                  <td className="one">
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click when clicking on the button
                        handleView(voter._id);
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default AlphabeticalList;
