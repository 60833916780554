import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./VoterList.css";

const BoothwiseReport = () => {
  const [voters, setVoters] = useState([]);
  const [error, setError] = useState(null);
  const [change, setChange] = useState("");
  const [filters, setFilters] = useState({
    Epic_ID: "",
    Name: "",
    Middle_Name: "",
    Age: "",
    Gender: "",
    Name_Eng: "",
    Marathi_Name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [votersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalVoterCount, setTotalVoterCount] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "Name",
    direction: "asc",
  });
  const navigate = useNavigate();

  // Fetch voters from the API
  useEffect(() => {
    const fetchVoterData = async () => {
      try {
        const response = await axios.get(
          `https://dhyas.site/api/user/data?page=${currentPage}&` +
            `Epic_ID=${filters.Epic_ID || ""}` +
            `&Name=${filters.Name || ""}` +
            `&Middle_Name=${filters.Middle_Name || ""}` +
            `&Age=${filters.Age || ""}` +
            `&Gender=${filters.Gender || ""}` +
            `&Name_Eng=${filters.Name_Eng || ""}` +
            `&Marathi_Name=${filters.Marathi_Name || ""}`,
          { withCredentials: true }
        );
        setTotalPages(Math.ceil(response.data.userCount / votersPerPage));
        setVoters(response.data.userData);
        setTotalVoterCount(response.data.totalCount);
      } catch (error) {
        setError("Failed to fetch voters. Please try again later.");
        console.error("Fetch voters error:", error);
      }
    };
    fetchVoterData();
  }, [currentPage, change]);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle filter input change
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setChange(!change);
  };

  const handleClearFilters = () => {
    setFilters({
      Epic_ID: "",
      Name: "",
      Middle_Name: "",
      Age: "",
      Gender: "",
      Name_Eng: "",
      Marathi_Name: "",
    });
    setCurrentPage(1); // Reset to first page after clearing filters
  };

  const handleLogout = () => {
    axios
      .post("https://dhyas.site/api/user/logout", null, {
        withCredentials: true,
      })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        setError("Logout failed. Please try again.");
        console.error("Logout error:", error);
      });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedVoters = [...voters].sort((a, b) => {
    const aValue = a[sortConfig.key] || ""; // Handle null or undefined values
    const bValue = b[sortConfig.key] || ""; // Handle null or undefined values
    if (aValue < bValue) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Navigate to voter's detailed view
  const handleView = (voterId) => {
    navigate(`/admin/voters/view/${voterId}`);
  };

  return (
    <div className="voter-list-container">
      <div className="header">
        <h1 className="top-box">Voter List</h1>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="filter-section">
        <div className="filter-input">
          <label>Name:</label>
          <input
            type="text"
            name="Name"
            value={filters.Name}
            onChange={handleFilterChange}
          />
        </div>

        <div className="filter-input">
          <label>Epic ID:</label>
          <input
            type="text"
            name="Epic_ID"
            value={filters.Epic_ID}
            onChange={handleFilterChange}
          />
        </div>

        <div className="filter-input filter-input-odd">
          <label>Middle Name:</label>
          <input
            type="text"
            name="Middle_Name"
            value={filters.Middle_Name}
            onChange={handleFilterChange}
          />
        </div>

        <div className="filter-input filter-input-odd">
          <label>Age:</label>
          <input
            type="number"
            name="Age"
            value={filters.Age}
            onChange={handleFilterChange}
          />
        </div>

        <div className="filter-input filter-input-even">
          <label>Gender:</label>
          <select
            name="Gender"
            value={filters.Gender}
            onChange={handleFilterChange}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="filter-input filter-input-even">
          <label>Name (English):</label>
          <input
            type="text"
            name="Name_Eng"
            value={filters.Name_Eng}
            onChange={handleFilterChange}
          />
        </div>

        <div className="filter-input filter-input-odd">
          <label>Marathi Name:</label>
          <input
            type="text"
            name="Marathi_Name"
            value={filters.Marathi_Name}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="filter-button-group">
        <button className="clear-button" onClick={handleFilterSubmit}>
          Apply Filters
        </button>
        <button className="clear-button" onClick={handleClearFilters}>
          Clear Filters
        </button>
        <button
          className="print-button no-print"
          onClick={() => window.print()}
        >
          Print List
        </button>
      </div>

      <div className="table-container">
        <table className="voter-table">
          <thead>
            <tr>
              <th onClick={() => handleSort("Epic_ID")}>Epic ID</th>
              <th onClick={() => handleSort("Name")}>Name</th>
              <th onClick={() => handleSort("Middle_Name")} className="one">Middle Name</th>
              <th onClick={() => handleSort("Age")} className="one">Age</th>
              <th onClick={() => handleSort("Gender")} className="one">Gender</th>
              <th onClick={() => handleSort("Name_Eng")}>Name (English)</th>
              <th onClick={() => handleSort("Marathi_Name")} className="one">Marathi Name</th>
              <th className="one">Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedVoters.map((voter) => (
              <tr
                key={voter.Epic_ID}
                onClick={() => handleView(voter._id)} // Navigate to voter's detail on row click
                style={{ cursor: "pointer" }} // Add cursor pointer style for better UX
              >
                <td>{voter.Epic_ID}</td>
                <td>{voter.Name}</td>
                <td className="one">{voter.Middle_Name}</td>
                <td className="one">{voter.Age}</td>
                <td className="one">{voter.Gender}</td>
                <td>{voter.Name_Eng}</td>
                <td className="one">{voter.Marathi_Name}</td>
                <td className="one">
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click when clicking on the button
                      handleView(voter._id);
                    }}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default BoothwiseReport;
