import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import EditVoter from "./EditVoter";
import bjplogo from "../assets/bjp.png";
import whatsappLogo from "../assets/whatsapp.png";
import congresslogo from "../assets/congress.png";
import sslogo from "../assets/shivsena-shinde.png";
import ssubtlogo from "../assets/shivsena-ubt.png";
import n1logo from "../assets/ncp1.jpeg";
import n2logo from "../assets/ncp2.png";
import mnslogo from "../assets/mns.png";
import "./VoterDetailsnew.css";

function VoterDetails() {
  const { voterId } = useParams();
  const navigate = useNavigate();
  const [voter, setVoter] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [voteStatus, setVoteStatus] = useState("Not Voted");

  const partyImages = {
    BJP: bjplogo,
    Congress: congresslogo,
    "Shivsena-Shinde": sslogo,
    "Shivsena-UBT": ssubtlogo,
    "NCP-A": n1logo,
    "NCP-S": n2logo,
    MNS: mnslogo,
  };
  const partyimg = localStorage.getItem("party");

  useEffect(() => {
    const fetchVoterDetails = async () => {
      try {
        const response = await axios.get(
          `https://dhyas.site/api/voters/${voterId}`,
          { withCredentials: true }
        );
        setVoter(response.data.voter);
        setVoteStatus(response.data.voter.voteStatus || "Not Voted");
      } catch (error) {
        setError("Failed to fetch voter details. Please try again later.");
        console.error("Error fetching voter details:", error);
      }
    };
    fetchVoterDetails();
  }, [voterId]);

  const handleUpdate = (updatedVoter) => {
    setVoter(updatedVoter);
    setIsEditing(false);
    setIsVisible(true);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const styles = `
      @media print {
        body {
          margin: 0;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        img { margin-left:15rem; }
        button { display: none !important; }
        .voter-details-container, .voter-details-table {
          width: 100%;
          border-collapse: collapse;
        }
        .voter-details-table th, .voter-details-table td {
          border: 1px solid #ddd;
          padding: 8px;
          font-size: 3.5rem;
        }
        .voter-details-table th {
          background-color: #f2f2f2;
        }
      }
    `;
    printWindow.document.write("<html><head><title>Voter Details</title>");
    printWindow.document.write(`<style>${styles}</style>`);
    printWindow.document.write("</head><body>");
    const partyImage = document.querySelector(".voter-details-container img");
    if (partyImage) {
      printWindow.document.write(partyImage.outerHTML);
    }
    const voterDetailsTable = document.querySelector(".voter-details-table");
    if (voterDetailsTable) {
      printWindow.document.write(voterDetailsTable.outerHTML);
    }
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleWhatsAppShare = () => {
    if (voter) {
      const message = `
        Voter Details:
        Assembly Name: Panvel
        Candidate: BJP
        Ballot_No: [Insert Ballot Number Here]

        ------------
        Epic ID: ${voter.Epic_ID}
        Name: ${voter.Name}
        Age: ${voter.Age}
        Gender: ${voter.Gender}
        Surname: ${voter.Voter_Surname_Eng}
        Polling Station Address: ${voter.Address_of_Polling_Station_Eng}
        Booth Name: ${voter.No_and_Name_of_Polling_Station_Eng}
      `;
      const encodedMessage = encodeURIComponent(message);
      window.open(`https://api.whatsapp.com/send?text=${encodedMessage}`, "_blank");
    }
  };

  const handleBack = () => navigate(-1);
  
  const handleLogout = async () => {
    try {
      await axios.post("https://dhyas.site/api/logout", {}, { withCredentials: true });
      navigate("/login");
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  if (error) return <p>{error}</p>;
  if (!voter) return <p>Loading voter details...</p>;

  return (
    <div className="voter-details-container">
      <img src={partyImages[partyimg]} alt={partyimg} style={{ width: "150px", marginBottom: "20px" }} />
      <h2>Voter Details</h2>

      <button onClick={handlePrint}>Print</button>
      <button onClick={handleWhatsAppShare} style={{ border: "none", background: "none", cursor: "pointer" }}>
        <img src={whatsappLogo} alt="Share on WhatsApp" style={{ width: "50px", marginRight: "10px", verticalAlign: "middle" }} />
        Share on WhatsApp
      </button>

      {isEditing ? (
        <EditVoter voterId={voterId} onUpdate={handleUpdate} />
      ) : (
        isVisible && (
          <table className="voter-details-table">
            <tbody>
              <tr><td><strong>Epic ID:</strong></td><td>{voter.Epic_ID}</td></tr>
              <tr><td><strong>Name:</strong></td><td>{voter.Name}</td></tr>
              <tr><td><strong>Middle Name:</strong></td><td>{voter.Middle_Name}</td></tr>
              <tr><td><strong>Age:</strong></td><td>{voter.Age}</td></tr>
              <tr><td><strong>Gender:</strong></td><td>{voter.Gender}</td></tr>
              <tr><td><strong>English Name:</strong></td><td>{voter.Middle_Name_Eng}</td></tr>
              <tr><td><strong>Shifted:</strong></td><td>{voter.Shiifted}</td></tr>
              <tr><td><strong>Email Id:</strong></td><td>{voter.EmailId}</td></tr>
              <tr><td><strong>DOB:</strong></td><td>{voter.DOB}</td></tr>
              <tr><td><strong>Caste:</strong></td><td>{voter.Caste}</td></tr>
              <tr><td><strong>Education:</strong></td><td>{voter.Education}</td></tr>
              <tr><td><strong>Party:</strong></td><td>{voter.Party}</td></tr>
              <tr><td><strong>Profession:</strong></td><td>{voter.Profession}</td></tr>
              <tr><td><strong>Ref1:</strong></td><td>{voter.ref1}</td></tr>
              <tr><td><strong>Main Address:</strong></td><td>{voter.MainAddress}</td></tr>
              <tr><td><strong>Vote Status:</strong></td><td>{voter.voteStatus}</td></tr>
              <tr><td><strong>Status:</strong></td><td>{voter.DeadorALive}</td></tr>
            </tbody>
          </table>
        )
      )}

      <button onClick={() => setIsEditing(!isEditing)}>{isEditing ? "Cancel Edit" : "Edit Voter"}</button>

      <div style={{ marginTop: "20px" }}>
        <button onClick={handleBack}>Back</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
}

export default VoterDetails;
