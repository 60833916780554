import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt, faList, faAddressCard, faUserCheck, faSearch,
  faCogs, faUsers, faCircle, faGenderless, faUserTag, faHeart,
  faBook, faExclamationTriangle, faBars, faTimes
} from '@fortawesome/free-solid-svg-icons';

import logoImage from '../assets/dhyas.png';
import './LeftSideMenuAdmin.css'; // Import the CSS file

function LeftSideMenuAdmin({ onLogout, isAdmin }) {
  const [isOpen, setIsOpen] = useState(false); // State to manage sidebar open/closed
  const [currentTime, setCurrentTime] = useState('');

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  // Close sidebar when clicking outside
  const handleOutsideClick = (event) => {
    const sidebarElement = document.querySelector('.sidebar');
    const toggleButton = document.querySelector('.toggle-button');
    if (
      isOpen &&
      sidebarElement &&
      !sidebarElement.contains(event.target) &&
      toggleButton &&
      !toggleButton.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      // Convert to Indian Standard Time (IST)
      const options = { timeZone: 'Asia/Kolkata', hour12: true };
      const timeString = now.toLocaleTimeString('en-US', options);
      const dateString = now.toLocaleDateString('en-IN', options);

      setCurrentTime(`${dateString} | ${timeString}`);
    };

    // Update time every second
    const intervalId = setInterval(updateTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Add event listener to close sidebar on outside click
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  // Close sidebar when a menu item is clicked
  const handleMenuClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* Toggle Button for Mobile */}
      <button className="toggle-button" onClick={toggleSidebar} aria-label="Toggle Menu">
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>

      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'open' : 'close'}`}>
        <img src={logoImage} alt="Logo" className="logo" />
        
        {/* Display Date and Time */}
        <div className="datetime">
          {currentTime}
        </div>

        <h2>{isAdmin ? "Admin Menu" : "User Menu"}</h2>
        {isAdmin ? (
          <>
            {/* <Link to="/admin/dashboard" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faTachometerAlt} className="link-icon" />
              Admin Dashboard
            </Link> */}
            <Link to="/admin/dashboard" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faTachometerAlt} className="link-icon" />
              Admin Dashboard
            </Link>
            <Link to="/admin/voters/list" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faList} className="link-icon" />
              Voters List
            </Link>
            <Link to="/admin/alphabeticalList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faAddressCard} className="link-icon" />
              Alphabetical List
            </Link>
            <Link to="/admin/agewiseList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUserCheck} className="link-icon" />
              Agewise List
            </Link>
            <Link to="/admin/familyReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUsers} className="link-icon" />
              Family Report
            </Link>
            <Link to="/admin/duplicateList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faSearch} className="link-icon" />
              Duplicate List
            </Link>
            <Link to="/admin/surnamewiseList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUserTag} className="link-icon" />
              Surnamewise List
            </Link>
            <Link to="/admin/deadOrAlive" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faCircle} className="link-icon" />
              Dead or Alive
            </Link>
            <Link to="/admin/redGreenReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faHeart} className="link-icon" />
              Red Green Report
            </Link>
            <Link to="/admin/addresswiseReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faAddressCard} className="link-icon" />
              Addresswise Report
            </Link>
            <Link to="/admin/boothwiseReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faCogs} className="link-icon" />
              Boothwise Report
            </Link>
            <Link to="/admin/genderReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faGenderless} className="link-icon" />
              Gender Report
            </Link>
            <Link to="/admin/casteReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUserTag} className="link-icon" />
              Caste Report
            </Link>
            <Link to="/admin/wardwiseReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faBook} className="link-icon" />
              Wardwise Report
            </Link>
            <Link to="/admin/bloodGroupReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faCircle} className="link-icon" />
              Blood Group Report
            </Link>
            <Link to="/admin/educationReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faBook} className="link-icon" />
              Education Report
            </Link>
            <Link to="/admin/shiftedReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faExclamationTriangle} className="link-icon" />
              Shifted Report
            </Link>
          </>
        ) : (
          <>
            {/* User Links */}
            <Link to="/user/dashboard" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faTachometerAlt} className="link-icon" />
              User Dashboard
            </Link>
            <Link to="/user/alphabeticalList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faAddressCard} className="link-icon" />
              Alphabetical List
            </Link>
            <Link to="/user/agewiseList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUserCheck} className="link-icon" />
              Agewise List
            </Link>
            <Link to="/user/familyReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUsers} className="link-icon" />
              Family Report
            </Link>
            <Link to="/user/duplicateList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faSearch} className="link-icon" />
              Duplicate List
            </Link>
            <Link to="/user/surnamewiseList" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUserTag} className="link-icon" />
              Surnamewise List
            </Link>
            <Link to="/user/deadOrAlive" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faCircle} className="link-icon" />
              Dead or Alive
            </Link>
            <Link to="/user/redGreenReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faHeart} className="link-icon" />
              Red Green Report
            </Link>
            <Link to="/user/addresswiseReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faAddressCard} className="link-icon" />
              Addresswise Report
            </Link>
            <Link to="/user/boothwiseReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faCogs} className="link-icon" />
              Boothwise Report
            </Link>
            <Link to="/user/genderReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faGenderless} className="link-icon" />
              Gender Report
            </Link>
            <Link to="/user/casteReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faUserTag} className="link-icon" />
              Caste Report
            </Link>
            <Link to="/user/wardwiseReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faBook} className="link-icon" />
              Wardwise Report
            </Link>
            <Link to="/user/bloodGroupReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faCircle} className="link-icon" />
              Blood Group Report
            </Link>
            <Link to="/user/educationReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faBook} className="link-icon" />
              Education Report
            </Link>
            <Link to="/user/shiftedReport" className="link" onClick={handleMenuClick}>
              <FontAwesomeIcon icon={faExclamationTriangle} className="link-icon" />
              Shifted Report
            </Link>
          </>
        )}
      </div>
    </>
  );
}

export default LeftSideMenuAdmin;
