import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LanguageSelection.css';
import dhyasImage from '../assets/dhyas.png'; // Make sure the path is correct

function LanguageSelection({ onSelectLanguage }) {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const navigate = useNavigate();

  const handleLanguageSelect = () => {
    if (selectedLanguage) {
      // Store the selected language in localStorage
      localStorage.setItem('language', selectedLanguage);
      localStorage.setItem('languageSelected', 'true');
      
      // Notify the parent component (if needed)
      onSelectLanguage(selectedLanguage);
      
      // Redirect to the login page
      navigate('/login');
    }
  };

  return (
    <div className="language-selection-container">
      {/* Add the image at the top */}
      <img src={dhyasImage} alt="Dhyas Logo" className="language-selection-image" />
      <h2>Select Your Language</h2>
      <select
        className="form-select"
        value={selectedLanguage}
        onChange={(e) => setSelectedLanguage(e.target.value)}
      >
        <option value="">Choose Language</option>
        <option value="english">English</option>
        <option value="hindi">Hindi</option>
        <option value="marathi">Marathi</option>
      </select>
      <button className="btn btn-primary mt-3" onClick={handleLanguageSelect}>
        Continue
      </button>
    </div>
  );
}

export default LanguageSelection;
