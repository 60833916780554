import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditVoter = ({ voterId, onUpdate }) => {
  const [voter, setVoter] = useState(null);
  const [formData, setFormData] = useState({
    Name: '',
    Middle_Name: '',
    Section: '',
    marathiName: '',
    mobileNumber: '',
    Shiifted: '', // New field for Shifted
    EmailId: '', // New field for Email Id
    DOB: '', // New field for DOB
    Education: '', // New field for Education
    Caste: '', // New field for Caste
    Party: '', // New field for Party
    Profession: '', // New field for Profession
    ref1: '', // New field for Reference 1
    ref2: '', // New field for Reference 2
    ref3: '', // New field for Reference 3
    ref4: '', // New field for Reference 4
    No_and_Name_of_Polling_Station_Eng: '', // New field for Main Address
    votestatus: 'Not Voted', // Default vote status
    DeadorALive: 'Alive', // Default dead/alive status
  });
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  // Fetch voter details
  useEffect(() => {
    const fetchVoter = async () => {
      try {
        const response = await axios.get(`https://dhyas.site/api/voters/${voterId}`, { withCredentials: true });
        const voterData = response.data.voter;

        // Set all state fields once voter data is fetched
        setVoter(voterData);
        setFormData({
          Name: voterData.Name || '',
          Middle_Name: voterData.Middle_Name || '',
          Section: voterData.Middle_Name_Eng || '',
          marathiName: voterData.Marathi_Name || '',
          mobileNumber: voterData.mobileNumber || '',
          Shiifted: voterData.Shifted || '', // New field
          EmailId: voterData.EmailId || '', // New field
          DOB: voterData.DOB || '', // New field
          Education: voterData.Education || '', // New field
          Caste: voterData.Caste || '', // New field
          Party: voterData.Party || '', // New field
          Profession: voterData.Profession || '', // New field
          ref1: voterData.ref1 || '', // New field
          ref2: voterData.ref2 || '', // New field
          ref3: voterData.ref3 || '', // New field
          ref4: voterData.ref4 || '', // New field
          No_and_Name_of_Polling_Station_Eng: voterData.No_and_Name_of_Polling_Station_Eng || '', // New field
          votestatus: voterData.voteStatus || 'Not Voted',
          DeadorALive: voterData.DeadorALive || 'Alive',
        });
      } catch (err) {
        setError(err.response ? err.response.data.message : 'An unexpected error occurred');
      }
    };

    fetchVoter();
  }, [voterId]);

  // Update voter data
  const handleUpdate = async () => {
    // Check if at least one field is filled
    const { Name, Middle_Name, Section, marathiName, mobileNumber, Shiifted, EmailId, DOB, Education, Caste, Party, Profession, ref1, ref2, ref3, ref4, No_and_Name_of_Polling_Station_Eng } = formData;

    if (!Name && !Middle_Name && !Section && !marathiName && !mobileNumber && !Shiifted && !EmailId && !DOB && !Education && !Caste && !Party && !Profession && !ref1 && !ref2 && !ref3 && !ref4 && !No_and_Name_of_Polling_Station_Eng) {
      setError('At least one field must be changed');
      return;
    }

    const updatedData = {
      Name,
      Middle_Name,
      Section,
      marathiName,
      mobileNumber,
      Shiifted, // New field
      EmailId, // New field
      DOB, // New field
      Education, // New field
      Caste, // New field
      Party, // New field
      Profession, // New field
      ref1, // New field
      ref2, // New field
      ref3, // New field
      ref4, // New field
      No_and_Name_of_Polling_Station_Eng, // New field
      votestatus: formData.votestatus,
      DeadorALive: formData.DeadorALive,
    };

    try {
      const response = await axios.put(
        `https://dhyas.site/api/voters/${voterId}`,
        updatedData,
        { withCredentials: true }
      );

      alert('Voter updated successfully');
      setIsEditing(false);
      onUpdate(response.data.voter);
    } catch (err) {
      setError(err.response ? err.response.data.message : 'An unexpected error occurred');
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Toggle edit mode and reset input fields
  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    if (!isEditing && voter) {
      setFormData({
        Name: voter.Name || '',
        Middle_Name: voter.Middle_Name || '',
        Section: voter.Middle_Name_Eng || '',
        marathiName: voter.Marathi_Name || '',
        mobileNumber: voter.mobileNumber || '',
        Shiifted: voter.Shifted || '', // Reset new field
        EmailId: voter.EmailId || '', // Reset new field
        DOB: voter.DOB || '', // Reset new field
        Education: voter.Education || '', // Reset new field
        Caste: voter.Caste || '', // Reset new field
        Party: voter.Party || '', // Reset new field
        Profession: voter.Profession || '', // Reset new field
        ref1: voter.ref1 || '', // Reset new field
        ref2: voter.ref2 || '', // Reset new field
        ref3: voter.ref3 || '', // Reset new field
        ref4: voter.ref4 || '', // Reset new field
        No_and_Name_of_Polling_Station_Eng: voter.No_and_Name_of_Polling_Station_Eng || '', // Reset new field
        votestatus: voter.voteStatus || 'Not Voted',
        DeadorALive: voter.DeadorALive || 'Alive',
      });
    }
  };

  return (
    <div>
      <h1>Edit Voter</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {voter ? (
        <div>
          <h3>Current Data:</h3>
          <p><strong>Name:</strong> {voter.Name}</p>
          <p><strong>Middle Name:</strong> {voter.Middle_Name}</p>
          <p><strong>English Name:</strong> {voter.Middle_Name_Eng}</p>
          <p><strong>Marathi Name:</strong> {voter.Marathi_Name}</p>
          <p><strong>Mobile Number:</strong> {voter.mobileNumber}</p>
          <p><strong>Vote Status:</strong> {voter.voteStatus || 'Not Voted'}</p>
          <p><strong>Dead or Alive:</strong> {voter.DeadorALive || 'Alive'}</p>

          {isEditing ? (
            <div>
              <h3>Edit Fields:</h3>
              <input
                type="text"
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
                placeholder="New Name"
              />
              <input
                type="text"
                name="Middle_Name"
                value={formData.Middle_Name}
                onChange={handleInputChange}
                placeholder="New Middle Name"
              />
              <input
                type="text"
                name="Section"
                value={formData.Section}
                onChange={handleInputChange}
                placeholder="New English Name"
              />
              <input
                type="text"
                name="marathiName"
                value={formData.marathiName}
                onChange={handleInputChange}
                placeholder="New Marathi Name"
              />
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleInputChange}
                placeholder="New Mobile Number"
              />
              <input
                type="text"
                name="Shiifted"
                value={formData.Shiifted}
                onChange={handleInputChange}
                placeholder="Shifted"
              />
              <input
                type="text"
                name="EmailId"
                value={formData.EmailId}
                onChange={handleInputChange}
                placeholder="Email ID"
              />
              <input
                type="text"
                name="DOB"
                value={formData.DOB}
                onChange={handleInputChange}
                placeholder="DOB"
              />
              <input
                type="text"
                name="Education"
                value={formData.Education}
                onChange={handleInputChange}
                placeholder="Education"
              />
              <input
                type="text"
                name="Caste"
                value={formData.Caste}
                onChange={handleInputChange}
                placeholder="Caste"
              />
              <input
                type="text"
                name="Party"
                value={formData.Party}
                onChange={handleInputChange}
                placeholder="Party"
              />
              <input
                type="text"
                name="Profession"
                value={formData.Profession}
                onChange={handleInputChange}
                placeholder="Profession"
              />
              <input
                type="text"
                name="ref1"
                value={formData.ref1}
                onChange={handleInputChange}
                placeholder="Reference 1"
              />
              <input
                type="text"
                name="ref2"
                value={formData.ref2}
                onChange={handleInputChange}
                placeholder="Reference 2"
              />
              <input
                type="text"
                name="ref3"
                value={formData.ref3}
                onChange={handleInputChange}
                placeholder="Reference 3"
              />
              <input
                type="text"
                name="ref4"
                value={formData.ref4}
                onChange={handleInputChange}
                placeholder="Reference 4"
              />
              <input
                type="text"
                name="No_and_Name_of_Polling_Station_Eng"
                value={formData.No_and_Name_of_Polling_Station_Eng}
                onChange={handleInputChange}
                placeholder="Main Address"
              />

              {/* Vote Status Radio Buttons */}
              <div>
                <label>
                  <input
                    type="radio"
                    name="votestatus"
                    value="Voted"
                    checked={formData.votestatus === "Voted"}
                    onChange={() => setFormData((prev) => ({ ...prev, votestatus: "Voted" }))}
                  />
                  Voted
                </label>
                <label style={{ marginLeft: "20px" }}>
                  <input
                    type="radio"
                    name="votestatus"
                    value="Not Voted"
                    checked={formData.votestatus === "Not Voted"}
                    onChange={() => setFormData((prev) => ({ ...prev, votestatus: "Not Voted" }))}
                  />
                  Not Voted
                </label>
              </div>

              {/* Dead or Alive Radio Buttons */}
              <div>
                <label>
                  <input
                    type="radio"
                    name="DeadorALive"
                    value="Dead"
                    checked={formData.DeadorALive === "Dead"}
                    onChange={() => setFormData((prev) => ({ ...prev, DeadorALive: "Dead" }))}
                  />
                  Dead
                </label>
                <label style={{ marginLeft: "20px" }}>
                  <input
                    type="radio"
                    name="DeadorALive"
                    value="Alive"
                    checked={formData.DeadorALive === "Alive"}
                    onChange={() => setFormData((prev) => ({ ...prev, DeadorALive: "Alive" }))}
                  />
                  Alive
                </label>
              </div>

              <button onClick={handleUpdate}>Update</button>
              <button onClick={handleEditToggle}>Cancel</button>
            </div>
          ) : (
            <button onClick={handleEditToggle}>Edit Voter</button>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default EditVoter;
