import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./VoterList.css";

const FamilyReport = () => {
  const [voters, setVoters] = useState([]);
  const [error, setError] = useState(null);
  const [change, setChange] = useState("");
  const [filters, setFilters] = useState({
    Epic_ID: "",
    Name: "",
    Middle_Name: "",
    Age: "",
    Gender: "",
    Name_Eng: "",
    Marathi_Name: "",
    House_No: "", // Added House_No to filters
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [votersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalVoterCount, setTotalVoterCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVoterData = async () => {
      try {
        const response = await axios.get(
          `https://dhyas.site/api/family/user/data?page=${currentPage}&` +
            `Epic_ID=${filters.Epic_ID || ""}` +
            `&Name=${filters.Name || ""}` +
            `&Middle_Name=${filters.Middle_Name || ""}` +
            `&Age=${filters.Age || ""}` +
            `&Gender=${filters.Gender || ""}` +
            `&Name_Eng=${filters.Name_Eng || ""}` +
            `&Marathi_Name=${filters.Marathi_Name || ""}` +
            `&House_No=${filters.House_No || ""}`, // Include House_No in API request
          { withCredentials: true }
        );

        console.log("API Response:", response.data); // Log the response for debugging

        setVoters(response.data.relatives);
        setTotalVoterCount(response.data.relatives.length); // Set the family count
        setTotalPages(Math.ceil(response.data.totalCount / votersPerPage));
      } catch (error) {
        setError("Failed to fetch voters. Please try again later.");
        console.error("Fetch voters error:", error);
      }
    };
    fetchVoterData();
  }, [currentPage, change]);

  // Handle filter input change
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleClearFilters = () => {
    setFilters({
      Epic_ID: "",
      Name: "",
      Middle_Name: "",
      Age: "",
      Gender: "",
      Name_Eng: "",
      Marathi_Name: "",
      House_No: "", // Clear House_No filter
    });
    setCurrentPage(1); // Reset to first page after clearing filters
  };

  const handleLogout = () => {
    axios
      .post("https://dhyas.site/api/user/logout", null, {
        withCredentials: true,
      })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        setError("Logout failed. Please try again.");
        console.error("Logout error:", error);
      });
  };

  return (
    <div className="voter-list-container">
      <div className="header">
        <h1 className="top-box">Family Report</h1>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="filter-section">
        <div className="filter-input">
          <label>Name/EPIC-ID:</label>
          <input
            type="text"
            name="Name"
            value={filters.Name}
            onChange={handleFilterChange}
            placeholder="Enter Name or EPIC-ID"
          />
        </div>
        <button onClick={() => setChange(Date.now())}>Search Family</button>
        <button onClick={handleClearFilters}>Clear Filters</button>
      </div>

      {/* Family count display */}
      <div className="family-count">
        <h2>Total Family Members Found: {totalVoterCount}</h2>
      </div>

      {/* Table for displaying family members */}
      <div className="table-container">
        <table className="voter-table">
          <thead>
            <tr>
              <th>Name (English)</th>
              <th>Relation Type</th>
              <th>Relative Name (English)</th>
            </tr>
          </thead>
          <tbody>
            {voters.map((data, index) => (
              <tr key={index}>
                <td>{data.Name_Eng}</td>
                <td>{data.Relation_Type_Eng}</td>
                <td>{data.Relative_Name_Eng}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FamilyReport;
